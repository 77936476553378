import React from "react";
import { ServiceOneData } from "@/data";
import ServiceCardTwo from "@/components/service-card-two";
import { Col, Row } from "react-bootstrap";

const RelatedServices = () => {
  return (
    <section className="service-style-three sec-pad service-details-page">
      <div className="thm-container">
        <div className="sec-title text-center">
          <span>Our Products</span>
          <h3>You’ll Find What You’re<br/> Looking For Here</h3>
        </div>
        <Row>
          {ServiceOneData.slice(0, 3).map((post, index) => (
            <Col md={6} lg={4} key={index}>
              <ServiceCardTwo data={post} />
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default RelatedServices;
